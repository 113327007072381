<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in" appear>
      <component :is="Component"><slot /></component>
    </transition>
  </router-view>
</template>

<script>

export default {
  name: "App",
  created() {
    this.$store.commit('setEntity', this.entity)
    if (this.user) {
      this.$store.commit('setUser', this.user)
      this.$store.commit('isLoggedin', true)
      this.$store.commit('setBaseUrl', this.base_url)
    }
  },
  props: ['user', 'base_url', 'entity']
}
</script>
